<template>
  <section class="main-section">
    <div class="history-section" v-if="timeline.length">
      <div class="history-card" v-for="(t, i) in timeline" :key="i">
        <div class="history-top">
          <div class="history-img">
            <img :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/history/' + t.date + '.png'"/>
          </div>
          <div class="history-date-title">
            <div class="history-date">{{ t.date }}</div>
            <div class="history-title">{{ t.title }}</div>
          </div>
        </div>
        <div class="history-big-img" v-if="t.bigImg"><img :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/history/' + t.date + '-b.png'"/></div>
        <div class="history-desc" v-html="t.desc"></div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    timeline: [
      {
        date: '1966',
        title: '品牌创建',
        desc: '创始⼈Herbert Maier在德国⿊森林凯泽施图尔⼭麓的巴林根建⽴了现代化的橱柜⽣产基地。',
        bigImg: false
      },
      {
        date: '1972',
        title: '品牌塑造',
        desc: '玛娅橱柜开始品牌化运作，玛娅的品牌化塑造进程由此开始。',
        bigImg: true
      },
      {
        date: '1982',
        title: '扩大生产',
        desc: '玛娅的原有产能已⽆法满⾜市场需求，5000平⽶的新⼚房开始兴建。',
        bigImg: false
      },
      {
        date: '1984',
        title: '自动化生产',
        desc: '玛娅⼯⼚引⼊数控设备，CNC加⼯中⼼和⾃动切割机为⾃动化⽣产带来了巨⼤的突破。',
        bigImg: false
      },
      {
        date: '1991',
        title: '快速发展',
        desc: '玛娅橱柜新展厅和办公楼开始兴建，玛娅进⼊快速发展期。',
        bigImg: false
      },
      {
        date: '1993',
        title: '全新展示',
        desc: '玛娅橱柜全新的近5000平⽶展厅正式开业。',
        bigImg: false
      },
      {
        date: '2000',
        title: '金牌匠人',
        desc: 'Herbert Maier先⽣在取得⼯匠资格40周年之际被授予⾦牌⼯匠称号。',
        bigImg: false
      },
      {
        date: '2006',
        title: '初识中国',
        desc: '承接⾸个中国订单，MAIER KÜCHEN向位于北京的项⽬出⼝300多套橱柜。',
        bigImg: false
      },
      {
        date: '2012',
        title: '中国战略',
        desc: '玛娅橱柜进⼊中国，成为率先在上海设⽴⼯⼚的德国⾼端定制橱柜品牌之⼀。',
        bigImg: false
      },
      {
        date: '2016',
        title: '新的方向',
        desc: '德国玛娅上海宜⼭路旗舰店正式开业，宣告玛娅正式进军零售市场。',
        bigImg: false
      },
      {
        date: '2018',
        title: '全屋战略',
        desc: '玛娅在中国制定了全新的品牌战略，开启全屋定制家居时代。',
        bigImg: false
      },
      {
        date: '2020',
        title: '全新标识',
        desc: '玛娅启⽤全新标识MAIER HOME，标志着玛娅向全屋家居⾏业全⼒进发。',
        bigImg: false
      }
    ]
  }),
  created () {
    this.$store.commit('updateActiveNav', null)
    // this.getAbout()
  },
  computed: {
    info () {
      return this.$store.getters.companyInfo
    }
  },
  methods: {
    getAbout () {
      this.https.get('about').then(response => {
        if (response.code === 0) {
          this.timeline = response.data.timeline
        }
      })
    }
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/about.css';
</style>
